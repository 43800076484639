/* .header-preguntas {
    /* margin-top: 205px; 

    margin-top: 220px;
    align-items: start;
  
} */


/* .header-preguntas {
    margin: 0;
    padding: 0;
    margin: 40px;
    flex-direction: column;
} */

.container-init {
    display: flex;
    flex-direction: row;
    align-content: flex-end ;   
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
}

.container-img {
    width: 60px;
    height: 50px; 
    border-radius: 8px;
    border: none;
    border-radius: 1px solid #F5F5F5;
    cursor: pointer;
    background: #F5F5F5;
    margin-top: 25px;
    /* box-shadow: inset 100px 100px 100px 100px #d1d1d19c ; */
    /* box-shadow: inset = es una sombra interna en el boton #c0c0c0ac */

    filter: grayscale(100%);
}

/* .container-init :hover {
    background: #CCCCCC;
    box-shadow: inset 0px 0px 0px 0px #CCCCCC ;
} */

.container-img.isActive {
    background: #F5F5F5;
    box-shadow: inset 0px 0px 0px 0px #CCCCCC ;
    filter: grayscale(0%);
}